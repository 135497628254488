<template>
	<div class="capture_image_list_wrapper">
		<ul v-show="mapImages.length">
			<li v-for="(image, i) in mapImages" :key="i">
				<div class="image_actionhover">
					<b-button pill variant="primary" size="sm" @click="cropImage(image, i)">
						{{ FormMSG(1, 'Crop') }}
					</b-button>
					<b-button pill variant="outline-danger" size="sm" @click="deleteImg(image)">{{ FormMSG(2, 'Delete') }}</b-button>
				</div>
				<img :src="rendSrc(image)" :alt="rendAlt(i)" />
			</li>
		</ul>
	</div>
</template>

<script>
import { isNil, isBase64 } from '@/shared/utils';

import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'CapturedImgListComponent',
	mixins: [languageMessages],
	props: {
		value: {
			type: Array,
			required: true
		}
	},
	computed: {
		mapImages: {
			get() {
				return this.value;
			},
			set(array) {
				console.log({ array });
				debugger;
				this.$emit('input', array);
			}
		}
	},
	methods: {
		/**
		 * @param {String} img
		 * @param {Number} index
		 */
		cropImage(img, index) {
			if (isNil(img)) return;
			this.$emit('crop', {
				img,
				index
			});
		},

		/**
		 * @param {String} imageID
		 */
		deleteImg(imageID) {
			// this.mapImages = this.value.splice(id, 1);
			const index = this.mapImages.indexOf(imageID);
			if (index > -1) this.mapImages.splice(index, 1);
		},

		/**
		 * @param {String} img
		 * @return {String}
		 */
		rendSrc(img) {
			if (isNil(img)) return;
			if (isBase64(img)) return img;
			const path = process.env.VUE_APP_PATH_IMG_URL;
			return path + img;
		},

		/**
		 * @param {String or Number} i
		 * @return {String}
		 */
		rendAlt(i) {
			return `captured_image_${i}`;
		}
	}
};
</script>
