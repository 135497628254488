<template>
	<div class="get_desktop_img_wrapper">
		<b-form-group :label="FormMSG(114, 'Select an image from your computer')" label-for="file-default">
			<b-form-file
				:browse-text="FormMSG(113, 'Browse')"
				:placeholder="FormMSG(114, 'Choose a file from your computer')"
				:accept="rendAccept"
				multiple
				id="file-default"
				@change="onFileSelected"
				ref="desktopImgFileId"
			/>
		</b-form-group>
	</div>
</template>

<script>
import { isNil } from '@/shared/utils';
import languageMessages from '@/mixins/languageMessages';
import { IMAGE_FILE_TYPE, PDF_FILE_TYPE } from '@/shared/constants';
import GlobalMixin from '@/mixins/global.mixin';
import { isFileSupported } from '@/utils/isFileSupported';

export default {
	name: 'GetDesktopImgComponent',
	mixins: [languageMessages, GlobalMixin],
	data() {
		return {
			selectedImages: []
		};
	},
	watch: {
		selectedImages(val) {
			this.$emit('change', val);
		}
	},
	methods: {
		/**
		 * @param {Object} $event
		 * { target, dataTransfer }
		 */
		onFileSelected($event) {
			const { files } = $event.target;
			if (isNil(files)) return;
			const keys = Object.keys(files);
			keys.forEach(async (k) => {
				if (isFileSupported(files[k], this.supportedMimeTypesList)) {
					this.createImage(files[k]);
				} else {
					this.createToastForMobile(
						this.FormMSG(117, 'Error'),
						files[k].name + ' : ' + this.FormMSG(116, 'The file format is not supported. Please upload a supported file.'),
						'',
						'danger'
					);
					this.$refs['desktopImgFileId'].reset();
				}
			});
		},

		// isFileSupported(file) {
		// 	const isSupported = () => {
		// 		const supportedTypes = this.supportedMimeTypes.split(',');
		// 		return supportedTypes.some((supportedType) => file.name.toLowerCase().endsWith(supportedType.trim()));
		// 	};
		// 	return isSupported();
		// },

		/**
		 * @param {Object} file
		 */
		createImage(file) {
			// var image = new Image();
			const reader = new FileReader();

			reader.onload = (e) => (this.selectedImages = e.target.result);
			reader.readAsDataURL(file);
		}
	},
	computed: {
		rendAccept() {
			return this.supportedMimeTypes;
		},
		supportedMimeTypes() {
			return IMAGE_FILE_TYPE + ',' + PDF_FILE_TYPE; // End of Selection
		},
		supportedMimeTypesList() {
			return [this.supportedMimeTypes];
		}
	}
};
</script>
