var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "capture_image_list_wrapper" }, [
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.mapImages.length,
            expression: "mapImages.length",
          },
        ],
      },
      _vm._l(_vm.mapImages, function (image, i) {
        return _c("li", { key: i }, [
          _c(
            "div",
            { staticClass: "image_actionhover" },
            [
              _c(
                "b-button",
                {
                  attrs: { pill: "", variant: "primary", size: "sm" },
                  on: {
                    click: function ($event) {
                      return _vm.cropImage(image, i)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.FormMSG(1, "Crop")) +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
              _c(
                "b-button",
                {
                  attrs: { pill: "", variant: "outline-danger", size: "sm" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteImg(image)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(2, "Delete")))]
              ),
            ],
            1
          ),
          _c("img", {
            attrs: { src: _vm.rendSrc(image), alt: _vm.rendAlt(i) },
          }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }