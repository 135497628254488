var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "get_desktop_img_wrapper" },
    [
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.FormMSG(114, "Select an image from your computer"),
            "label-for": "file-default",
          },
        },
        [
          _c("b-form-file", {
            ref: "desktopImgFileId",
            attrs: {
              "browse-text": _vm.FormMSG(113, "Browse"),
              placeholder: _vm.FormMSG(114, "Choose a file from your computer"),
              accept: _vm.rendAccept,
              multiple: "",
              id: "file-default",
            },
            on: { change: _vm.onFileSelected },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }